import dynamic from "next/dynamic";
import { useState } from "react";
import { ChevronUp, ChevronDown } from "../../../lib/icons";
import EditorJSContent from "../../editor-js-content";
import { NextImage } from "../../elements";
import { Section } from "../index";

import {
  MiniCardGridWrapper,
  MobileImageHeader,
  ShowAllButton,
  Description,
} from "./styles";

const MiniCardItem = dynamic(() =>
  import("../../elements/mini-card-item/mini-card-item")
);

export const MiniCardGrid = ({
  title,
  sectionSubtitle,
  hideLineSeparation,
  description,
  showAll: showAllLabel,
  showLess: showLessLabel,
  image,
  listOnMobile,
  miniCardItems,
  displaySetting

}) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <Section
      title={title}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      {image && (
        <MobileImageHeader>
          <NextImage media={image} />
        </MobileImageHeader>
      )}
      {description && (
        <Description>
          <EditorJSContent content={description} />
        </Description>
      )}
      <MiniCardGridWrapper listOnMobile={listOnMobile}>
        {miniCardItems.map((miniCard) => (
          <MiniCardItem
            key={miniCard.id}
            {...miniCard}
            listOnMobile={listOnMobile}
            isVisibleOnMobile={showAll}
          />
        ))}
      </MiniCardGridWrapper>
      {showAllLabel && !showAll && (
        <ShowAllButton
          onClick={() => setShowAll(true)}
          listOnMobile={listOnMobile}
        >
          {showAllLabel}
          {ChevronDown}
        </ShowAllButton>
      )}
      {showLessLabel && showAll && (
        <ShowAllButton
          onClick={() => setShowAll(false)}
          listOnMobile={listOnMobile}
        >
          {showLessLabel}
          {ChevronUp}
        </ShowAllButton>
      )}
    </Section>
  );
};

export default MiniCardGrid;
