import styled, { css } from "styled-components";
import { ButtonGhost } from "../../elements";

export const Description = styled.div`
  font-size: var(--textS);
  line-height: var(--lineHeightS);
  margin: var(--spacingXs) 0;

  p {
    margin: 0;
  }

  @media screen and (min-width: 1128px) {
    font-size: var(--textM);
    line-height: var(--lineHeightM);
  }
`;

export const MiniCardGridWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - var(--spacingS) / 2));
  grid-template-rows: auto;
  gap: var(--spacingS);
  width: 100%;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;

  @media screen and (max-width: 1128px) {
    ${({ listOnMobile }) =>
      listOnMobile &&
      css`
        display: flex;
        flex-direction: column;
        gap: 0;
        align-items: center;
      `}  
  }
  > * { 
    width:100%;
  }
  li {
    border-bottom: 1px solid var(--gray200);
    padding: var(--spacingS);
  }
  
  @media screen and (min-width: 1128px) {
    grid-template-columns: repeat(6, 168px);
    gap: var(--spacingM);
  }
`;

export const ShowAllButton = styled(ButtonGhost)`
  margin-top: var(--spacingS);
  justify-content: space-between;
  font-size: var(--textS);
  line-height: var(--lineHeightS);

  > svg {
    width: 16px;
    height: 16px;
  }

  ${({ listOnMobile }) =>
    listOnMobile &&
    css`
      margin-top: var(--spacingXs);
    `}

  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

export const MobileImageHeader = styled.div`
  display: block;
  margin-bottom: var(--spacingXs);

  > img {
    border-radius: var(--radiusL);
  }

  @media screen and (min-width: 1128px) {
    display: none;
  }
`;

export default MiniCardGridWrapper;
